<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12 w-full">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field v-model="email" prepend-icon="person" name="email" label="Email" type="email"></v-text-field>
                <v-text-field v-model="password" id="password" prepend-icon="lock" name="password" label="Password" type="password"></v-text-field>
                <span v-show="error" class="red--text">Invalid credentials. Please try again.</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="isLoginButtonDisabled" @click.prevent="onLoginClick">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      error: false,
    };
  },
  computed: {
    ...mapState(['user']),
    isLoginButtonDisabled() {
      return this.email.trim() === '' || this.password.trim() === '';
    },
  },
  methods: {
    ...mapActions(['login']),
    async onLoginClick() {
      this.error = false;
      if (this.email.trim() !== '' && this.password.trim() !== '') {
        const user = await this.login({ email: this.email, password: this.password });

        if (user) {
          this.redirectToHome();
        } else {
          this.error = true;
        }
      }
    },
    redirectToHome() {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect = urlParams.get('redirect');
      if (redirect) {
        window.location = redirect;
      } else {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
      }
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.redirectToHome();
        }
      },
    },
  },
};
</script>
