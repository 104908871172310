const rules = {
  required: (v) => !!v || 'This field is required',
  moreThanTwo: (v) => (v && v.length > 2) || 'This field must be more than 2 characters',
  email: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Invalid e-mail';
  },
};

export default {
  data() {
    return {
      $_rules: {
        required: rules.required(),
      },
    };
  },
};
