<template>
  <v-dialog v-model="dialog" persistent max-width="900px" scrollable @keydown.esc="cancel">
    <Form ref="form" v-model="valid" lazy-validation @submit="save" onSubmit="return false;">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span class="headline">Create Event</span>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="name" :rules="[rules.required]" autocomplete="off" label="Event Name*" required dense hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <DatePicker ref="event-date" :rules="[rules.required]" v-model="date" label="Event Date" dense hide-details="auto" />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="location" :rules="[rules.required]" autocomplete="off" label="City*" dense hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <autocomplete
                  v-model="countryId"
                  :rules="[rules.required]"
                  :items="getCountriesArray"
                  item-text="name"
                  item-value="code"
                  label="Country*"
                  dense
                  hide-details="auto"
                  clearable
                ></autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <autocomplete
                  v-model="selectedTechAreas"
                  :rules="[rules.required, rules.requiredArray]"
                  :items="techAreas"
                  item-text="name"
                  item-value="id"
                  label="Innovation Area(s)*"
                  dense
                  multiple
                  hide-details="auto"
                ></autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select v-model="type" :items="eventTypes" :rules="[rules.required]" item-text="name" item-value="id" label="Type of Event*" dense hide-details="auto"></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea v-model="notes" label="Notes" required dense hide-details="auto"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-checkbox v-if="!event" v-model="createAnother" label="Create Another" dense />
          <v-btn class="ml-3 px-5" @click="cancel">Cancel</v-btn>
          <v-btn class="ml-3 px-5" color="primary" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </Form>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { mapState, mapActions, mapGetters } from 'vuex';
import { rules } from '@/helpers/validation';
import DatePicker from '../ui/DatePicker';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';

export default {
  name: 'CreateEventModal',
  components: { DatePicker },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      location: '',
      countryId: '',
      date: '',
      selectedTechAreas: [],
      type: '',
      notes: '',
      dialog: true,
      createAnother: false,
      valid: true,
      rules,
    };
  },
  computed: {
    ...mapState(['eventTypes', 'techAreas', 'formDirty']),
    ...mapGetters(['getCountriesArray']),
  },
  created() {
    if (this.event) {
      const { name, location, country, date, notes, techAreas, type } = this.event;

      const parsedDate = dayjs(date).format(DEFAULT_DATE_FORMAT);

      this.name = name;
      this.location = location;
      this.countryId = country?.code;
      this.date = parsedDate;
      this.notes = notes;
      this.selectedTechAreas = techAreas.map((i) => i.id);
      this.type = type;
    }
  },
  methods: {
    ...mapActions(['createEvent', 'updateEvent']),
    allowedDates(date) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return Date.parse(date) > yesterday;
    },
    validate() {
      return this.$refs.form.validate();
    },
    async save() {
      const isValid = this.validate();

      if (!isValid) {
        return;
      }
      const [day, month, year] = this.date.split('/');
      const date = new Date(Date.parse(`${month}/${day}/${year}`)).toISOString();

      const eventObject = {
        name: this.name,
        location: this.location,
        countryId: this.countryId,
        date: date,
        notes: this.notes,
        techAreaIds: this.selectedTechAreas,
        type: this.type,
      };

      if (this.event) {
        eventObject.id = this.event.id;
        await this.updateEvent(eventObject);
      } else {
        await this.createEvent(eventObject);
      }

      if (this.createAnother) {
        this.clearFields();
      } else {
        this.close();
      }

      this.reset();
    },
    reset() {
      this.$refs.form.reset();
    },
    clearFields() {
      this.name = '';
      this.location = '';
      this.countryId = '';
      this.date = '';
      this.notes = '';
      this.selectedTechAreas = [];
      this.type = '';
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
