const BASE_URL = process.env.VUE_APP_ENDPOINT_URL;

export async function post(url = '', data = {}, headers = {}, json = true) {
  // Default options are marked with *
  const options = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
    body: data, // body data type must match 'Content-Type' header
  };

  if (json) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(data);
  }

  return fetch(`${BASE_URL}${url}`, options);
}

export async function del(url = '', data = {}, headers = {}) {
  // Default options are marked with *
  return fetch(`${BASE_URL}${url}`, {
    method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // body data type must match 'Content-Type' header
  });
}

export async function put(url = '', data = {}, headers = {}) {
  // Default options are marked with *
  return fetch(`${BASE_URL}${url}`, {
    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // body data type must match 'Content-Type' header
  });
}

export async function get(url = '', headers = {}) {
  // Default options are marked with *
  return fetch(`${BASE_URL}${url}`, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
  });
}
