<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :rules="rules"
        :label="label"
        :prepend-icon="!hideIcon ? 'mdi-calendar' : undefined"
        readonly
        v-bind="attrs"
        v-on="on"
        :dense="dense"
        hide-details="auto"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" :allowed-dates="allowedDates" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate, parseDate } from '@/helpers/datetime';

export default {
  name: 'DatePicker',
  props: {
    label: String,
    value: [Date, String],
    allowedDates: {
      type: Function,
      default: (v) => v,
    },
    rules: Array,
    dense: Boolean,
    hideIcon: Boolean,
    defaultToday: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu: false,
      date: this.defaultToday ? new Date().toISOString().substr(0, 10) : null,
      formattedDate: null,
    };
  },
  methods: {},
  watch: {
    date: {
      handler: function () {
        this.formattedDate = formatDate(this.date);
        this.$emit('input', this.formattedDate);
      },
      immediate: true,
    },
    value: {
      handler: function () {
        if (this.value) {
          this.date = parseDate(this.value);
          this.formattedDate = formatDate(this.date);
        }
      },
      immediate: true,
    },
  },
};
</script>
