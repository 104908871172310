<template>
  <div class="splash-screen">
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplashScreen',
};
</script>

<style lang="scss">
.splash-screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .loader-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loader-wrapper.hide {
    display: none;
  }
  .loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background: #ab0000;
    background: -moz-linear-gradient(left, #ab0000 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ab0000 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ab0000 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ab0000 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ab0000 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #ab0000;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: #ffffff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
