<template>
  <div class="view-item">
    <label>{{ label }}</label>
    <div>
      <div v-html="textHtml"></div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewItem',
  props: {
    label: String,
    text: [String, Number],
  },
  computed: {
    textHtml() {
      return this.text?.replace(/(?:\r\n|\r|\n)/g, '<br>') || '';
    },
  },
};
</script>
<style lang="scss" scoped>
.view-item {
  label {
    font-size: 13px;
  }
  div {
    color: black;
  }
}
</style>
