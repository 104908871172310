import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import toast from './helpers/toast';

import './styles/main.scss';
import vuetify from './plugins/vuetify';
import modal from './plugins/modal';
import Autocomplete from '@/components/ui/Autocomplete';
import Form from '@/components/ui/Form';
import ViewItem from '@/components/ui/ViewItem';

Vue.config.productionTip = false;

// Register UI components
Vue.component('autocomplete', Autocomplete);
Vue.component('Form', Form);
Vue.component('ViewItem', ViewItem);

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    Vue.prototype.$toast = toast;
  },
}).$mount('#app');

router.beforeEach((to, from, next) => {
  store.commit('CLEAR_SEARCH_FILTER_DATA');
  next();
});

Vue.use(modal, { appInstance: app });
