import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import Login from '@/views/Login';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/individual/list',
    name: 'IndividualList',
    component: () => import(/* webpackChunkName: "individual-list" */ '../views/individual/List.vue'),
  },
  {
    path: '/simple-match',
    name: 'SimpleMatch',
    component: () => import(/* webpackChunkName: "simple-match" */ '../views/SimpleMatch.vue'),
  },
  {
    path: '/simple-match-result',
    name: 'SimpleMatchResult',
    component: () => import(/* webpackChunkName: "simple-match-result" */ '../views/SimpleMatchResult.vue'),
  },
  {
    path: '/multi-match',
    name: 'MultiMatch',
    component: () => import(/* webpackChunkName: "multi-match" */ '../views/MultiMatch.vue'),
  },
  {
    path: '/multi-match-result',
    name: 'MultiMatchResult',
    component: () => import(/* webpackChunkName: "multi-match-result" */ '../views/MultiMatchResult.vue'),
  },
  {
    path: '/event/list',
    name: 'EventList',
    component: () => import(/* webpackChunkName: "event-list" */ '../views/event/List.vue'),
  },
  {
    path: '/company/list',
    name: 'CompanyList',
    component: () => import(/* webpackChunkName: "company-list" */ '../views/company/List.vue'),
  },
  {
    path: '/funder/list',
    name: 'FunderCompanyList',
    component: () => import(/* webpackChunkName: "funder-company-list" */ '../views/funder/List.vue'),
  },
  {
    path: '/admin/challenges',
    name: 'Challenges',
    component: () => import(/* webpackChunkName: "challenges" */ '../views/admin/Challenges.vue'),
  },
  {
    path: '/admin/outcomes',
    name: 'Outcomes',
    component: () => import(/* webpackChunkName: "outcomes" */ '../views/admin/Outcomes.vue'),
  },
  {
    path: '/admin/tech-areas',
    name: 'TechAreas',
    component: () => import(/* webpackChunkName: "tech-areas" */ '../views/admin/TechAreas.vue'),
  },
  {
    path: '/admin/business-models',
    name: 'BusinessModels',
    component: () => import(/* webpackChunkName: "business-models" */ '../views/admin/BusinessModels.vue'),
  },
  {
    path: '/admin/industries',
    name: 'Industries',
    component: () => import(/* webpackChunkName: "industries" */ '../views/admin/Industries.vue'),
  },
  {
    path: '/admin/tags',
    name: 'Tags',
    component: () => import(/* webpackChunkName: "tags" */ '../views/admin/Tags.vue'),
  },
  {
    path: '/admin/company-types',
    name: 'CompanyTypes',
    component: () => import(/* webpackChunkName: "company-types" */ '../views/admin/CompanyTypes.vue'),
  },
  {
    path: '/admin/funding-types',
    name: 'FundingTypes',
    component: () => import(/* webpackChunkName: "company-funding-types" */ '../views/admin/FundingTypes.vue'),
  },
  {
    path: '/admin/funder-roles',
    name: 'FunderRoles',
    component: () => import(/* webpackChunkName: "company-funder-roles" */ '../views/admin/FunderRoles.vue'),
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/admin/Users.vue'),
  },
  {
    path: '/admin/gdpr-options',
    name: 'GdprOptions',
    component: () => import(/* webpackChunkName: "gdpr-options" */ '../views/admin/GdprOptions.vue'),
  },
  {
    path: '/admin/import-data',
    name: 'ImportData',
    component: () => import(/* webpackChunkName: "import-data" */ '../views/admin/ImportData.vue'),
  },
  {
    path: '/admin/import-individual-registered-events',
    name: 'ImportIndividualRegisteredEvents',
    component: () => import(/* webpackChunkName: "import-individual-registered-events" */ '../views/admin/ImportIndividualRegisteredEvents.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
