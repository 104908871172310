<script>
import { VForm } from 'vuetify/lib';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Form',
  extends: VForm,
  data() {
    return {
      timeout: null,
    };
  },
  mounted() {
    this.$on('input', this.onInputHandler);
    this.$on('keyup', this.onKeyupHandler);
  },
  computed: {
    ...mapState(['formDirty']),
  },
  methods: {
    ...mapMutations(['SET_FORM_DIRTY']),
    onInputHandler() {
      if (!this.formDirty) {
        this.SET_FORM_DIRTY(true);
      }
    },
    onKeyupHandler() {
      console.log('onKeyupHandler');
    },
  },
  beforeDestroy() {
    this.SET_FORM_DIRTY(false);
  },
};
</script>
