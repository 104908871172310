<template>
  <div class="home">
    <v-row>
      <v-col sm="6" md="4" lg="3">
        <v-card>
          <v-card-title>
            <v-avatar color="success" size="30" class="mr-3">
              <v-icon color="white" size="18">mdi-account</v-icon>
            </v-avatar>
            <span class="text-18">Individuals</span>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div>
                <div class="text-sm font-weight-bold">Total</div>
                <div class="text-2xl" style="font-weight: bolder">{{ dashboardData.individualCount }}</div>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn small text outlined @click="openCreateIndividualModal">
                  <v-icon small>mdi-plus</v-icon>
                  Create
                </v-btn>
              </div>
            </div>
            <div v-if="false" class="d-flex align-end">
              <v-text-field placeholder="Search" hide-details class="mt-0"></v-text-field>
              <v-btn icon class="mb-n1"><v-icon>mdi-magnify</v-icon></v-btn>
            </div>
            <div>
              <v-btn class="mt-4" outlined text small block style="text-transform: none" @click="$router.push('/individual/list')">
                See Details
                <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="4" lg="3">
        <v-card>
          <v-card-title>
            <v-avatar color="yellow darken-2" size="30" class="mr-3">
              <v-icon color="white" size="18">mdi-account</v-icon>
            </v-avatar>
            <span class="text-18">Events</span>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div>
                <div class="text-sm font-weight-bold">Total</div>
                <div class="text-2xl" style="font-weight: bolder">{{ dashboardData.eventCount }}</div>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn small text outlined @click="openCreateEventModal">
                  <v-icon small>mdi-plus</v-icon>
                  Create
                </v-btn>
              </div>
            </div>
            <div v-if="false" class="d-flex align-end">
              <v-text-field placeholder="Search" hide-details class="mt-0"></v-text-field>
              <v-btn icon class="mb-n1"><v-icon>mdi-magnify</v-icon></v-btn>
            </div>
            <div>
              <v-btn class="mt-4" outlined text small block style="text-transform: none" @click="$router.push('/event/list')">
                See Details
                <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="4" lg="3">
        <v-card>
          <v-card-title>
            <v-avatar color="purple" size="30" class="mr-3">
              <v-icon color="white" size="18">mdi-account</v-icon>
            </v-avatar>
            <span class="text-18">Organisations</span>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div>
                <div class="text-sm font-weight-bold">Total</div>
                <div class="text-2xl" style="font-weight: bolder">{{ dashboardData.companyCount }}</div>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn small text outlined @click="openCreateCompanyModal">
                  <v-icon small>mdi-plus</v-icon>
                  Create
                </v-btn>
              </div>
            </div>
            <div v-if="false" class="d-flex align-end">
              <v-text-field placeholder="Search" hide-details class="mt-0"></v-text-field>
              <v-btn icon class="mb-n1"><v-icon>mdi-magnify</v-icon></v-btn>
            </div>
            <div>
              <v-btn class="mt-4" outlined text small block style="text-transform: none" @click="$router.push('/company/list')">
                See Details
                <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="4" lg="3">
        <v-card>
          <v-card-title>
            <v-avatar color="accent" size="30" class="mr-3">
              <v-icon color="white" size="18">mdi-account</v-icon>
            </v-avatar>
            <span class="text-18">Funders</span>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div>
                <div class="text-sm font-weight-bold">Total</div>
                <div class="text-2xl" style="font-weight: bolder">{{ dashboardData.funderCount }}</div>
              </div>
              <div class="d-flex align-center justify-end">
                <v-btn small text outlined @click="openCreateFunderModal">
                  <v-icon small>mdi-plus</v-icon>
                  Create
                </v-btn>
              </div>
            </div>
            <div v-if="false" class="d-flex align-end">
              <v-text-field placeholder="Search" hide-details class="mt-0"></v-text-field>
              <v-btn icon class="mb-n1"><v-icon>mdi-magnify</v-icon></v-btn>
            </div>
            <div>
              <v-btn class="mt-4" outlined text small block style="text-transform: none" @click="$router.push('/funder/list')">
                See Details
                <v-icon class="ml-1">mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="4" lg="3">
        <v-card>
          <v-card-title>
            <v-avatar color="accent" size="38" class="mr-3">
              <v-icon color="white" size="18">mdi-database-import</v-icon>
            </v-avatar>
            <span class="text-18">Import Data</span>
            <v-spacer />
            <div>
              <v-btn class="mt-2" small elevation="0" style="text-transform: none" fab @click="$router.push('/admin/import-data')">
                <v-icon>mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CreateIndividualModal from '@/components/individual/CreateIndividualModal';
import CreateEventModal from '@/components/event/CreateEventModal';
import CreateCompanyModal from '@/components/company/CreateCompanyModal';

export default {
  name: 'Home',
  data() {
    return {
      dashboardData: {},
    };
  },
  created() {
    this.fetchDashboardData().then((response) => {
      this.$set(this, 'dashboardData', response);
    });
  },
  computed: {
    ...mapGetters(['getFunderCompanyTypeId']),
  },
  methods: {
    ...mapActions(['fetchDashboardData']),
    openCreateIndividualModal() {
      this.$showModal(CreateIndividualModal);
    },
    openCreateEventModal() {
      this.$showModal(CreateEventModal);
    },
    openCreateCompanyModal() {
      this.$showModal(CreateCompanyModal);
    },
    openCreateFunderModal() {
      this.$showModal(CreateCompanyModal, { defaultCompanyTypeId: this.getFunderCompanyTypeId });
    },
  },
};
</script>
