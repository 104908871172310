<template>
  <div class="fixed top-0 mt-19 max-w-350 z-1000 right-0 mr-5">
    <transition-group name="notification" tag="div">
      <div
        class="w-full flex transition-all shadow-md duration-300 border-0 border-l-4 border-solid text-14 rounded-4 bg-white relative mb-4 overflow-hidden max-h-150"
        :class="[notification.type === 'default' ? 'border-indigo-500' : '', notification.type === 'success' ? 'border-green-500' : '', notification.type === 'error' ? 'border-red-500' : '']"
        v-for="notification in notificationState"
        :key="notification.key"
      >
        <div class="text-left flex px-6 py-4 flex-col justify-center">
          <div v-if="notification.title" class="font-700">{{ notification.title }}</div>
          <div class="text-gray-500">{{ notification.message }}</div>
        </div>
        <div class="close transition-all duration-300 flex cursor-pointer text-22 text-gray-600 align-center max-w-50 py-4 px-4" @click="closeToastMessage(notification.key)">×</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { ToastEventEmitter } from '@/helpers/toast';

export default {
  name: 'Toast',
  data() {
    return {
      notificationState: [],
    };
  },
  created() {
    ToastEventEmitter.on('NotificationAdd', this.showToastMessage);
    ToastEventEmitter.on('NotificationRemove', this.closeToastMessage);
  },
  methods: {
    showToastMessage(payload) {
      this.notificationState.push(payload);
    },
    closeToastMessage(key) {
      const findIndex = this.notificationState.findIndex((item) => item.key === key);
      this.notificationState.splice(findIndex, 1);
    },
  },
};
</script>

<style scoped>
.close:hover {
  background-color: #ddd;
  color: #222;
}

/* Vue Animations */
.notification-enter {
  opacity: 0;
  transform: translateX(384px);
}

.notification-leave-to {
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.notification-leave-active {
  position: absolute;
}
</style>
