<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable @keydown.esc="cancel">
      <div v-if="loading">
        <v-card>
          <v-card-text>
            <v-container>
              <v-progress-circular class="mx-auto flex" indeterminate color="primary"></v-progress-circular>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
      <Form v-else ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar max-height="64" dark color="primary" style="margin-top: -1px; border-radius: 0">
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ id ? 'Update' : 'Create' }} Organisation</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <div class="d-flex items-center pb-1"><v-checkbox v-if="!company" v-model="createAnother" label="Create Another" dense hide-details /></div>
              <v-btn dark text class="ml-3 px-5" @click="cancel">Cancel</v-btn>
              <v-btn dark text class="ml-3 px-5" @click="save">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container fluid style="max-width: 100%">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="name" label="Organisation Name*" :rules="[rules.required]" required dense hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="trackingStats" label="Tracking Stats" dense hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete
                    v-model="selectedCompanyType"
                    :disabled="!!defaultCompanyTypeId"
                    :rules="[rules.required]"
                    :items="getTopLevelCompanyTypes"
                    item-text="name"
                    item-value="id"
                    label="Type of Organisation*"
                    hide-details="auto"
                    required
                    dense
                    @input="selectedSubCompanyStage = ''"
                  ></autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete
                    v-model="selectedSubCompanyStage"
                    :disabled="!companyStages.length"
                    :items="companyStages"
                    item-text="name"
                    item-value="id"
                    label="Sub-Type of Organisation*"
                    hide-details="auto"
                    required
                    dense
                    :rules="selectedSubCompanyStageRules"
                  ></autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="location" label="City" hide-details="auto" required dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete v-model="region" :items="regions" item-text="name" item-value="id" label="Region" hide-details="auto" dense></autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <autocomplete
                    v-model="countryId"
                    name="country"
                    :items="getCountriesArray"
                    item-text="name"
                    item-value="code"
                    label="Country"
                    hide-details="auto"
                    autocomplete="off"
                    dense
                    clearable
                  ></autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="website" label="Website" hide-details="auto" required dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="description" label="Description" hide-details="auto" required dense></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="SCInsights" label="SC Insights" hide-details="auto" required dense></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="bio" label="Tracking Log" required hide-details="auto" dense></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <autocomplete v-model="selectedTags" :items="tags" item-text="name" item-value="id" label="Tags(s)" multiple hide-details="auto" dense></autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <autocomplete v-model="selectedChallenges" :items="challenges" item-text="name" item-value="id" label="Challenge(s)" multiple hide-details="auto" dense></autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete v-model="selectedOutcomes" :items="outcomes" item-text="name" item-value="id" label="Outcome(s) of Interest" multiple hide-details="auto" dense></autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete v-model="selectedTechAreas" :items="techAreas" item-text="name" item-value="id" label="Innovation Area(s)" multiple hide-details="auto" dense></autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete v-model="selectedIndustries" multiple :items="industries" item-text="name" item-value="id" label="Industry" dense hide-details="auto"></autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="teamSize" label="Team Size" hide-details="auto" required dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="founded" label="Founded" hide-details="auto" required dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-checkbox v-model="raisingNow" label="Raising Now (in past quarter)" hide-details="auto" class="mt-0 pt-0" />
                </v-col>

                <template v-if="isFunder">
                  <v-col cols="12" sm="12" md="12" class="mt-2 mb-0 pb-0">
                    <div class="font-weight-bold">Funder Type:</div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete
                      v-model="fundingTypeIds"
                      :rules="isFunder ? [rules.required, rules.requiredArray] : []"
                      required
                      multiple
                      :items="fundingTypes"
                      item-text="name"
                      item-value="id"
                      label="Type of Funding*"
                      dense
                      hide-details="auto"
                    ></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete
                      v-model="fundingStageIds"
                      :rules="isFunder ? [rules.required, rules.requiredArray] : []"
                      multiple
                      :items="fundingStages"
                      item-text="name"
                      item-value="id"
                      label="Stages of Interest*"
                      dense
                      hide-details="auto"
                    ></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete
                      v-model="fundingTicketSizeIds"
                      :rules="isFunder ? [rules.required, rules.requiredArray] : []"
                      multiple
                      :items="fundingTicketSizes"
                      item-text="name"
                      item-value="id"
                      label="Ticket Size of Interest*"
                      dense
                      hide-details="auto"
                    ></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="fundingMinimumMonthlyRevenue" prefix="£" type="number" label="Minimum Revenue per month" dense hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <DatePicker
                      ref="last-active-date"
                      v-model="fundingLastActive"
                      :rules="isFunder ? [rules.required] : []"
                      label="Last Active*"
                      :default-today="false"
                      dense
                      hide-icon
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete v-model="selectedBusinessModels" multiple :items="businessModels" item-text="name" item-value="id" label="Business Model(s)" dense hide-details="auto"></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete v-model="selectedFundingRoles" multiple :items="fundingRoles" item-text="name" item-value="id" label="Funder Role(s)" dense hide-details="auto"></autocomplete>
                  </v-col>
                </template>

                <template v-if="isStartup">
                  <v-col cols="12" sm="12" md="12" class="mt-2 mb-0 pb-0">
                    <div class="font-weight-bold">Requirements</div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="amountCurrentlyRaising" label="Amount Currently Raising" hide-details="auto" required dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="currentValuation" prefix="£" type="number" label="Current Valuation" hide-details="auto" required dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="minimumRevenuePerMonth" prefix="£" type="number" label="Minimum Revenue per month" dense hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete v-model="ticketSizeIds" multiple :items="ticketSizes" item-text="name" item-value="id" label="Ticket Size of Interest" dense hide-details="auto"></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="fundingRaised" label="Funding Raised" dense hide-details="auto"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete v-model="selectedBusinessModels" multiple :items="businessModels" item-text="name" item-value="id" label="Business Model(s)" dense hide-details="auto"></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="pitchDeckUrl" label="Pitch Deck URL" dense hide-details="auto"></v-text-field>
                  </v-col>
                </template>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field v-model="geographyOfFocus" label="Geography of Focus" dense hide-details="auto"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <autocomplete
                    v-model="selectedPreviousChallenges"
                    :items="challenges"
                    item-text="name"
                    item-value="id"
                    label="Previous Challenges Registered"
                    multiple
                    hide-details="auto"
                    dense
                  ></autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="d-flex">
                  <v-checkbox v-model="superConnectMe" label="Super Connect Me" hide-details="auto" class="mt-0 pt-0" />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
        </v-card>
      </Form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { rules } from '@/helpers/validation';
import DatePicker from '@/components/ui/DatePicker';
import dayjs from 'dayjs';
import { formatDate, parseDate } from '@/helpers/datetime';
import ModalScrollRemover from '@/mixins/ModalScrollRemover';

export default {
  name: 'CreateCompanyModal',
  components: { DatePicker },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    defaultCompanyTypeId: {
      type: Number,
      default: null,
    },
  },
  mixins: [ModalScrollRemover],
  data() {
    return {
      loading: false,
      dialog: true,
      valid: true,
      createAnother: false,
      name: '',
      trackingStats: '',
      bio: '',
      location: '',
      region: '',
      countryId: '',
      website: '',
      description: '',
      SCInsights: '',
      raisingNow: false,
      amountCurrentlyRaising: null,
      currentValuation: null,
      minimumRevenuePerMonth: null,
      fundingRaised: null,
      ticketSizeIds: [],
      selectedBusinessModels: [],
      selectedFundingRoles: [],
      geographyOfFocus: '',
      pitchDeckUrl: '',
      selectedTags: [],
      selectedPreviousChallenges: [],
      selectedChallenges: [],
      selectedOutcomes: [],
      selectedCompanyType: '',
      selectedSubCompanyStage: '',
      selectedTechAreas: [],
      selectedIndustries: [],
      fundingTypeIds: [],
      fundingStageIds: [],
      fundingTicketSizeIds: [],
      fundingMinimumMonthlyRevenue: null,
      fundingLastActive: '',
      superConnectMe: false,
      teamSize: null,
      founded: null,
      company: null,
      rules,
    };
  },
  computed: {
    ...mapState([
      'challenges',
      'tags',
      'regions',
      'outcomes',
      'techAreas',
      'industries',
      'companyTypes',
      'fundingTypes',
      'fundingTicketSizes',
      'fundingStages',
      'businessModels',
      'fundingRoles',
      'formDirty',
    ]),
    ...mapGetters(['getCountriesArray', 'getFunderCompanyTypeId', 'getStartupCompanyTypeId', 'getTopLevelCompanyTypes']),
    ticketSizes() {
      return this.fundingTicketSizes;
    },
    isFunder() {
      return this.getFunderCompanyTypeId === this.selectedCompanyType;
    },
    isStartup() {
      return this.getStartupCompanyTypeId === this.selectedCompanyType;
    },
    companyStages() {
      if (this.selectedCompanyType) {
        return this.companyTypes.filter((c) => c.parent === this.selectedCompanyType);
      }
      return [];
    },
    selectedSubCompanyStageRules() {
      return this.companyStages.length ? [rules.required] : [];
    },
  },
  created() {
    if (this.defaultCompanyTypeId) {
      this.selectedCompanyType = this.defaultCompanyTypeId;
    }

    if (this.id) {
      this.loading = true;
      this.fetchCompanyById(this.id)
        .then((res) => {
          this.$set(this, 'company', res);

          if (this.company) {
            const {
              name,
              trackingStats,
              location,
              region,
              description,
              SCInsights,
              website,
              amountCurrentlyRaising,
              currentValuation,
              minimumRevenuePerMonth,
              fundingRaised,
              businessModels,
              fundingRoles,
              geographyOfFocus,
              ticketSizes,
              bio,
              country,
              tags,
              industries,
              previousChallenges,
              challenges,
              outcomes,
              techAreas,
              companyType,
              companyStage,
              fundingTypes,
              fundingTicketSizes,
              fundingStages,
              funding,
              raisingNow,
              superConnectMe,
              teamSize,
              founded,
              pitchDeckUrl,
            } = this.company;
            this.name = name;
            this.trackingStats = trackingStats;
            this.location = location;
            this.region = region;
            this.bio = bio;
            this.description = description;
            this.SCInsights = SCInsights;
            this.website = website;
            this.raisingNow = raisingNow || false;
            this.amountCurrentlyRaising = amountCurrentlyRaising === '' ? null : amountCurrentlyRaising;
            this.fundingRaised = fundingRaised === '' ? null : fundingRaised;
            this.currentValuation = currentValuation === 0 ? 0 : currentValuation === '' ? null : currentValuation;
            this.minimumRevenuePerMonth = minimumRevenuePerMonth === '' ? null : minimumRevenuePerMonth;
            this.selectedBusinessModels = businessModels.map((i) => i.id);
            this.selectedFundingRoles = fundingRoles.map((i) => i.id);
            this.ticketSizeIds = ticketSizes?.map((i) => i.id) || [];
            this.geographyOfFocus = geographyOfFocus;
            this.countryId = country?.code;
            this.selectedTags = tags.map((i) => i.id);
            this.selectedIndustries = industries.map((i) => i.id);
            this.selectedChallenges = challenges.map((i) => i.id);
            this.selectedPreviousChallenges = previousChallenges.map((i) => i.id);
            this.selectedOutcomes = outcomes.map((i) => i.id);
            this.selectedTechAreas = techAreas.map((i) => i.id);
            this.selectedCompanyType = companyType?.id;
            this.selectedSubCompanyStage = companyStage?.id;
            this.fundingTypeIds = fundingTypes.map((i) => i.id);
            this.fundingStageIds = fundingStages.map((i) => i.id);
            this.fundingTicketSizeIds = fundingTicketSizes.map((i) => i.id);
            this.fundingLastActive = funding?.lastActive ? formatDate(funding.lastActive.substr(0, 10)) : '';
            this.fundingMinimumMonthlyRevenue = funding?.minimumRevenuePerMonth === 0 ? 0 : funding?.minimumRevenuePerMonth || null;
            this.superConnectMe = superConnectMe;
            this.teamSize = teamSize;
            this.founded = founded;
            this.pitchDeckUrl = pitchDeckUrl;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    ...mapActions(['createCompany', 'updateCompany', 'fetchCompanyById']),
    allowedDates(date) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return Date.parse(date) > yesterday;
    },
    async save() {
      let success = false;
      if (!this.$refs.form.validate()) {
        return;
      }

      let fundingLastActive = null;
      if (this.fundingLastActive) {
        fundingLastActive = dayjs(parseDate(this.fundingLastActive)).toISOString();
      }

      const companyObject = {
        name: this.name,
        trackingStats: this.trackingStats,
        location: this.location,
        region: this.region,
        bio: this.bio,
        description: this.description,
        SCInsights: this.SCInsights,
        website: this.website,
        raisingNow: this.raisingNow,
        amountCurrentlyRaising: this.amountCurrentlyRaising,
        fundingRaised: this.fundingRaised,
        currentValuation: this.currentValuation === '0' ? 0 : this.currentValuation || null,
        minimumRevenuePerMonth: this.minimumRevenuePerMonth === '0' ? 0 : this.minimumRevenuePerMonth || null,
        ticketSizes: this.ticketSizeIds,
        businessModels: this.selectedBusinessModels,
        fundingRoles: this.selectedFundingRoles,
        geographyOfFocus: this.geographyOfFocus,
        countryId: this.countryId,
        industries: this.selectedIndustries,
        tags: this.selectedTags,
        challenges: this.selectedChallenges,
        previousChallenges: this.selectedPreviousChallenges,
        outcomes: this.selectedOutcomes,
        companyTypeId: this.selectedCompanyType,
        companyStageId: this.selectedSubCompanyStage,
        techAreas: this.selectedTechAreas,
        fundingTypes: this.fundingTypeIds,
        fundingStages: this.fundingStageIds,
        fundingTicketSizes: this.fundingTicketSizeIds,
        fundingMinimumMonthlyRevenue: this.fundingMinimumMonthlyRevenue === '0' ? 0 : this.fundingMinimumMonthlyRevenue || null,
        fundingLastActive: fundingLastActive,
        superConnectMe: this.superConnectMe,
        teamSize: this.teamSize,
        founded: this.founded,
        pitchDeckUrl: this.pitchDeckUrl,
      };

      if (this.company) {
        companyObject.id = this.company.id;
        const res = await this.updateCompany(companyObject);
        success = !!res;
      } else {
        const res = await this.createCompany(companyObject);
        success = !!res;
      }

      if (!success) {
        return;
      }

      if (this.createAnother) {
        this.clearFields();
      } else {
        this.close();
      }
      this.$refs.form.reset();

      if (this.defaultCompanyTypeId) {
        this.selectedCompanyType = this.defaultCompanyTypeId;
      }
    },
    clearFields() {
      this.name = '';
      this.trackingStats = '';
      this.bio = '';
      this.location = '';
      this.countryId = '';
      this.website = '';
      this.description = '';
      this.SCInsights = '';
      this.amountCurrentlyRaising = '';
      this.fundingRaised = null;
      this.currentValuation = null;
      this.minimumRevenuePerMonth = null;
      this.ticketSizeIds = [];
      this.selectedBusinessModels = [];
      this.selectedFundingRoles = [];
      this.geographyOfFocus = '';
      this.selectedTags = [];
      this.selectedIndustries = [];
      this.selectedChallenges = [];
      this.selectedPreviousChallenges = [];
      this.selectedOutcomes = [];
      this.selectedCompanyType = '';
      this.selectedSubCompanyStage = '';
      this.selectedTechAreas = [];
      this.fundingTypeIds = [];
      this.fundingStageIds = [];
      this.fundingTicketSizeIds = [];
      this.fundingMinimumMonthlyRevenue = null;
      this.fundingLastActive = '';
      this.raisingNow = false;
      this.superConnectMe = false;
      this.teamSize = null;
      this.founded = null;
      this.pitchDeckUrl = null;
      this.company = null;
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
