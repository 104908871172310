<script>
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'autocomplete',
  extends: VAutocomplete,
  methods: {
    selectItem(item) {
      VAutocomplete.options.methods.selectItem.call(this, item);
      this.internalSearch = '';
    },
  },
};
</script>
