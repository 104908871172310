<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable @keydown.esc="cancel">
    <Form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-toolbar max-height="64" dark color="primary" style="margin-top: -1px; border-radius: 0">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ individual ? 'Update' : 'Create' }} Individual</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <div class="d-flex items-center pb-1"><v-checkbox v-if="!individual" v-model="createAnother" label="Create Another" dense hide-details /></div>
            <v-btn dark text class="ml-3 px-5" @click="cancel">Cancel</v-btn>
            <v-btn dark text class="ml-3 px-5" @click="save">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid style="max-width: 100%">
            <v-row>
              <v-col cols="12" sm="12" md="7">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name*" hide-details="auto" required dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name*" hide-details="auto" required dense></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="email"
                      ref="email"
                      :rules="[rules.required, rules.email]"
                      :error-messages="checkEmailError ? [checkEmailError] : []"
                      hide-details="auto"
                      label="Email*"
                      required
                      dense
                      @blur="checkIndividualExist"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select v-model="gender" :items="genders" label="Gender" hide-details="auto" required dense></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="phone" label="Phone" dense hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="d-flex">
                    <v-text-field v-model="avatarUrl" label="Avatar URL" dense hide-details="auto"></v-text-field>
                    <v-file-input v-if="false" hide-input truncate-length="15" class="pt-0" hide-details="auto"></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="location" label="City" required dense hide-details="auto"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <autocomplete v-model="countryId" :items="getCountriesArray" item-text="name" item-value="code" label="Country" dense hide-details="auto" clearable></autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea v-model="bio" label="Bio" required dense hide-details="auto"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical />
              <v-col cols="12" sm="12" md="5">
                <v-tabs v-model="tab" height="30" :centered="false" grow :show-arrows="false" class="hide-prev">
                  <v-tab style="text-transform: capitalize">Organisation</v-tab>
                  <v-tab style="text-transform: capitalize">Add’l Info</v-tab>
                  <v-tab style="text-transform: capitalize">GDPR</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="mt-2">
                  <v-tab-item class="pt-3">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="d-flex">
                        <autocomplete
                          v-model="company"
                          :items="companies"
                          item-text="name"
                          item-value="id"
                          label="Organisation"
                          return-object
                          dense
                          hide-details="auto"
                          :error-messages="companyErrors"
                        ></autocomplete>
                        <v-btn class="ml-3 mr-1" small elevation="2" icon dark color="primary" @click="openCreateCompanyModal"><v-icon>mdi-plus</v-icon></v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="jobTitle" label="Job Title" required dense hide-details="auto"></v-text-field>
                      </v-col>
                      <div v-if="individualCompaniesError" class="red--text px-3">
                        {{ individualCompaniesError }}
                      </div>
                      <v-col cols="12" sm="12" md="12">
                        <v-btn block small :disabled="disableIndividualCompany" @click="addIndividualCompany">ADD</v-btn>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-divider class="mb-1" />
                        <v-list dense>
                          <v-subheader>Companies</v-subheader>
                          <div v-if="!individualCompanies.length" class="text-caption pl-2">No organisation is added yet</div>
                          <v-list-item v-for="(item, i) in individualCompanies" :key="i" dense>
                            <v-list-item-content>
                              <div class="d-flex">
                                <div class="mr-2">{{ item.company.name }}</div>
                                <v-chip color="indigo" text-color="white" x-small>{{ item.jobTitle }}</v-chip>
                              </div>
                            </v-list-item-content>
                            <v-list-item-action class="pa-0 my-0">
                              <v-btn icon>
                                <v-icon small color="grey lighten-1" @click="removeIndividualCompany(i)">mdi-delete</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="pt-3">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="webSite" label="Web Site" required dense hide-details="auto"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-model="linkedIn" label="LinkedIn" required dense hide-details="auto"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea v-model="notes" label="Notes" required dense hide-details="auto"></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="d-flex">
                        <v-checkbox v-model="superConnectMe" label="Super Connect Me" hide-details="auto" class="mt-0 pt-0" />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="pt-3">
                    <v-list dense>
                      <v-list-item v-for="(item, i) in gdprOptions" :key="i" dense>
                        <v-list-item-content>
                          <div class="d-flex align-center">
                            <v-checkbox
                              :input-value="individualGdprOptionIds.indexOf(item.id) !== -1"
                              small
                              color="grey lighten-1"
                              hide-details="auto"
                              class="mt-0 pt-0"
                              @change="checkGdprOption($event, item)"
                            >
                              mdi-delete
                            </v-checkbox>
                            <div class="mr-2 text-caption">{{ item.name }}</div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="grey" small v-bind="attrs" v-on="on" class="mt-0 pt-0">mdi-information</v-icon>
                              </template>
                              <span>{{ item.description }}</span>
                            </v-tooltip>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
      </v-card>
    </Form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { GENDER_ARRAY } from '@/helpers/constants';
import { rules } from '@/helpers/validation';
import CreateCompanyModal from '@/components/company/CreateCompanyModal';

export default {
  name: 'CreateIndividualModal',
  props: {
    individual: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      valid: true,
      firstName: '',
      lastName: '',
      email: '',
      gender: null,
      bio: '',
      company: null,
      jobTitle: null,
      webSite: '',
      linkedIn: '',
      notes: '',
      avatarUrl: '',
      phone: '',
      location: '',
      countryId: '',
      superConnectMe: false,
      individualGdprOptionIds: [],
      individualCompanies: [],
      createAnother: false,
      genders: GENDER_ARRAY,
      tab: 0,
      lastCheckedEmail: '',
      checkEmailError: '',
      individualCompaniesError: '',
      rules,
    };
  },
  computed: {
    ...mapState(['companies', 'gdprOptions', 'formDirty']),
    ...mapGetters(['getCountriesArray']),
    disableIndividualCompany() {
      if (!this.company || !this.jobTitle) {
        return true;
      } else {
        return this.companyAlreadyAdded;
      }
    },
    companyAlreadyAdded() {
      return this.company && this.individualCompanies.some((item) => item.company.id === this.company.id);
    },
    companyErrors() {
      return this.companyAlreadyAdded ? ['This organisation is already added!'] : null;
    },
  },
  created() {
    // EDIT MODE
    if (this.individual) {
      const { firstName, lastName, email, gender, bio, webSite, linkedIn, notes, avatarUrl, phone, location, superConnectMe, country, companyIndividuals, allowedGdprOptions } = JSON.parse(
        JSON.stringify(this.individual),
      );
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.gender = gender;
      this.bio = bio;
      this.webSite = webSite;
      this.linkedIn = linkedIn;
      this.notes = notes;
      this.avatarUrl = avatarUrl;
      this.phone = phone;
      this.location = location;
      this.countryId = country?.code || '';
      this.superConnectMe = superConnectMe;
      this.individualGdprOptionIds = allowedGdprOptions.map((item) => item.id);
      this.individualCompanies = companyIndividuals;
    }
    this.fetchCompanies();
  },
  methods: {
    ...mapActions(['createIndividual', 'updateIndividual', 'fetchCompanies', 'fetchIndividualByEmail']),
    async checkIndividualExist() {
      if (!this.$refs.email.validate()) {
        return;
      }

      if (this.lastCheckedEmail !== this.email) {
        this.lastCheckedEmail = this.email;

        const individual = await this.fetchIndividualByEmail(this.email);
        if (individual) {
          this.checkEmailError = 'This email has been already registered.';
        } else {
          this.checkEmailError = '';
        }
      }
    },
    validate() {
      const valid = this.$refs.form.validate();

      const hasCompany = this.individualCompanies.length;

      if (!hasCompany) {
        this.individualCompaniesError = 'At least one organisation is required';
      }

      return valid && hasCompany;
    },
    reset() {
      this.$refs.form.reset();

      this.checkEmailError = '';
      this.individualCompaniesError = '';
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    openCreateCompanyModal() {
      this.$showModal(CreateCompanyModal);
    },
    checkGdprOption(selected, item) {
      if (selected) {
        if (!this.individualGdprOptionIds.includes(item.id)) {
          this.individualGdprOptionIds.push(item.id);
        }
      } else {
        const index = this.individualGdprOptionIds.indexOf(item.id);
        this.individualGdprOptionIds.splice(index, 1);
      }
    },
    addIndividualCompany() {
      this.individualCompanies.push({
        company: this.company,
        jobTitle: this.jobTitle,
      });

      this.company = null;
      this.jobTitle = null;
      this.individualCompaniesError = '';
    },
    removeIndividualCompany(index) {
      this.individualCompanies.splice(index, 1);
    },
    save() {
      const valid = this.validate();

      if (!valid || this.checkEmailError) {
        return;
      }

      const individualObject = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        gender: this.gender || 0,
        bio: this.bio,
        webSite: this.webSite,
        linkedIn: this.linkedIn,
        notes: this.notes,
        avatarUrl: this.avatarUrl,
        phone: this.phone,
        location: this.location,
        countryId: this.countryId,
        superConnectMe: this.superConnectMe,
        individualCompanies: this.individualCompanies,
        individualGdprOptionIds: this.individualGdprOptionIds,
      };

      if (this.individual) {
        individualObject.id = this.individual.id;
        this.updateIndividual(individualObject);
      } else {
        this.createIndividual(individualObject);
      }

      if (this.createAnother) {
        this.clearFields();
      } else {
        this.close();
      }

      this.reset();
    },
    clearFields() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.gender = null;
      this.bio = '';
      this.jobTitle = null;
      this.company = null;
      this.webSite = '';
      this.linkedIn = '';
      this.notes = '';
      this.avatarUrl = '';
      this.phone = '';
      this.location = '';
      this.countryId = '';
      this.superConnectMe = false;
      this.individualCompanies = [];
      this.individualGdprOptionIds = [];
      this.tab = 0;
      this.lastCheckedEmail = '';
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
