<template>
  <div id="app" class="min-h-full">
    <component :is="layoutComponent" />
    <Toast />
  </div>
</template>
<script>
import DefaultLayout from './components/layouts/DefaultLayout';
import SideLayout from './components/layouts/SideLayout';
import Toast from './components/ui/Toast';

import FormValidation from '@/mixins/FormValidation';
import { mapState } from 'vuex';

export default {
  components: { Toast, SideLayout, DefaultLayout },
  computed: {
    ...mapState(['formDirty']),
    layoutComponent() {
      const layout = this.$route.meta?.layout;
      switch (layout) {
        case 'public':
          return DefaultLayout;
        default:
          return SideLayout;
      }
    },
  },
  mounted() {
    window.onbeforeunload = (e) => {
      if (this.formDirty) {
        e = e || window.event;

        // For IE and Firefox prior to version 4
        if (e) {
          e.returnValue = 'Sure?';
        }

        // For Safari
        return 'Sure?';
      }
    };
  },
  mixins: [FormValidation],
};
</script>
<style lang="scss">
@import 'assets/main';
</style>
