export const GENDER = {
  UNKNOWN: 0,
  MALE: 1,
  FEMALE: 2,
};

export const GENDER_ARRAY = [
  {
    text: 'Unknown',
    value: GENDER.UNKNOWN,
  },
  {
    text: 'Male',
    value: GENDER.MALE,
  },
  {
    text: 'Female',
    value: GENDER.FEMALE,
  },
];

export const REGIONS = [
  'North of England',
  'South of England',
  'London',
  'Scotland',
  'Wales',
  'Northern Ireland',
  'Republic of Ireland',
  'Central & Eastern Europe',
  'Southern Europe',
  'France',
  'BeNeLux',
  'DACH',
  'Nordics',
  'Australia and New Zealand',
  'North America',
  'South America',
  'Africa',
  'Asia',
  'Unknown',
];

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
