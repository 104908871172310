export const rules = {
  required: (v) => v === 0 || !!v || 'This field is required',
  requiredArray: (v) => (v && v.length > 0) || 'This field is required',
  moreThanTwo: (v) => (v && v.length > 2) || 'This field must be more than 2 characters',
  email: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Invalid e-mail';
  },
  password: (value) => {
    const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    return pattern.test(value) || 'Must contain at least one number and one uppercase and lowercase letter, and at least 6 or more characters';
  },
};
