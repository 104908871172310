import EventEmitter from '@/helpers/event-emitter';

export const ToastEventEmitter = new EventEmitter();

export function showToastMessage(payload) {
  let args = payload;
  if (typeof args === 'string') {
    args = { message: args };
  }
  args.message = args.message.substring(0, 150);
  args.key = new Date().getTime();
  args.duration = args.duration || 4500;
  args.type = args.type || 'default';
  // Set a timer to kill the notification after the specified duration
  args.timer = setTimeout(() => {
    ToastEventEmitter.emit('NotificationRemove', args.key);
  }, args.duration);
  ToastEventEmitter.emit('NotificationAdd', args);
}

export default {
  show: (text) => {
    showToastMessage(text);
  },
  success: (text, params) => {
    const payload = params ? { payload: { ...params, type: 'success' }, message: text } : { message: text, type: 'success' };
    showToastMessage(payload);
  },
  error: (text, params) => {
    const payload = params ? { payload: { ...params, type: 'error' }, message: text } : { message: text, type: 'error' };
    showToastMessage(payload);
  },
};
