<template>
  <v-dialog v-model="dialog" max-width="360" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="headline">Confirm Action</v-card-title>

      <v-card-text>Are you sure you want to delete this item?</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="cancel">CANCEL</v-btn>

        <v-btn color="green darken-1" text @click="accept">ACCEPT</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    autoCloseOnCancel: {
      type: Boolean,
      default: true,
    },
    autoCloseOnConfirm: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');

      if (this.autoCloseOnCancel) {
        this.dialog = false;
        this.$modal.delayedClose();
      }
    },
    accept() {
      this.$emit('confirm');

      if (this.autoCloseOnConfirm) {
        this.dialog = false;
        this.$modal.delayedClose();
      }
    },
  },
};
</script>
